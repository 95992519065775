import {useState} from 'react'
import './App.css';
import { MainLogo } from './mainlogo';
import { AboutUs } from './AboutUs';
import { Partner } from './Partner';
import Divider from '@mui/material/Divider';
import { Footer } from './Footer';
import { Nav } from './nav';

function App() {
  const onboarding = sessionStorage.getItem('onboarding')
  const [showOnboarding, setShowOnboarding] = useState(onboarding === null ? true : false);
  const onVideoFinished = () => setShowOnboarding(false);
  return (
    <div className="App">
      <div className='InnerApp'>
        {showOnboarding && <div className='OnBoarding'>
          <video controls={false} autoPlay muted onLoadStart={(e)=>{e.target.playbackRate = 5}} width="100%" onEnded={onVideoFinished}>
            <source src="/on-boarding.mp4" type="video/mp4" />
          </video>
        </div>}
        <Nav />
        <MainLogo />
        <div className='Video-showroom'>
          <video controls poster="/video-poster.png" width="80%">
            <source src="https://daftnreal.oss-cn-beijing.aliyuncs.com/Videos/DnR%20Showreel%202021.m4v" type="video/mp4" />
          </video>
        </div>
        <AboutUs />
        <Partner />
        <div style={{padding: "48px 0"}}>
          <Divider sx={{borderColor: "#FFF"}}/>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
