import Typography from '@mui/material/Typography';

export const AboutUs = () => (
  <div className='About-us-box'>
  <div className='About-us-title'/>
  <div className='About-us-content'>
  <Typography variant="h6" component="p" align='left' sx={{color:'white'}}>
  <div className='About-us-logo'/>制作成立于2013年，是一家创作型影视制作团队。
  </Typography>
  <Typography variant="h6" component="p" align='left' sx={{color:'white'}}>
  致力于制作富有创造力、高品质的商业广告及影视内容。
  </Typography>
  <Typography variant="h6" component="p" align='left' sx={{color:'white', mt: '1.5rem'}}>
  总部位于
    <Typography className='Highlight-typo' variant="h4" component="span" align='left' sx={{color:'white'}}>
    北京
    </Typography>
  ，并在
    <Typography className='Highlight-typo' variant="h4" component="span" align='left' sx={{color:'white'}}>
    上海
    </Typography>
    设有分部。
  </Typography>
  <Typography variant="h6" component="p" align='left' sx={{color:'white'}}>
  主创团队具有多年的影视制作经验及行业背景，
  </Typography>
  <Typography variant="h6" component="p" align='left' sx={{color:'white'}}>
  对每一个项目需求进行独到思考，提供适合的执行方案。
  </Typography>
  <Typography variant="h6" component="p" align='left' sx={{color:'white'}}>
  赋予影片更丰富的内涵和独到的表现方式是我们的己任。
  </Typography>
  <Typography variant="h6" component="p" align='left' sx={{color:'white', mt: '1.5rem'}}>
  多年的专业制作经验使德融制作建立了行业内顶尖的国内外制作资源生态群：
  </Typography>
  <Typography variant="h6" component="p" align='left' sx={{color:'white'}}>
  导演 - 合作
    <Typography className='Highlight-typo' variant="h4" component="span" align='left' sx={{color:'white'}}>
    一线导演
    </Typography>
  、开发扶持
    <Typography className='Highlight-typo' variant="h4" component="span" align='left' sx={{color:'white'}}>
    新人导演
    </Typography>
  </Typography>
  <Typography variant="h6" component="p" align='left' sx={{color:'white'}}>
  主创 - 长期合作行业
    <Typography className='Highlight-typo' variant="h4" component="span" align='left' sx={{color:'white'}}>
    一线摄影
    </Typography>
  、
    <Typography className='Highlight-typo' variant="h4" component="span" align='left' sx={{color:'white'}}>
    美术
    </Typography>
  等前期团队，身影遍布两岸三地
  </Typography>
  <Typography variant="h6" component="p" align='left' sx={{color:'white'}}>
  后期 - 长期合作
    <Typography className='Highlight-typo' variant="h4" component="span" align='left' sx={{color:'white'}}>
    英国
    </Typography>
  、
    <Typography className='Highlight-typo' variant="h4" component="span" align='left' sx={{color:'white'}}>
    波兰
    </Typography>
  、
    <Typography className='Highlight-typo' variant="h4" component="span" align='left' sx={{color:'white'}}>
    以色列
    </Typography>
  、
    <Typography className='Highlight-typo' variant="h4" component="span" align='left' sx={{color:'white'}}>
    澳洲
    </Typography>
  等顶尖后期公司，为后期品质保驾护航
  </Typography>
  </div>
</div>
)