import './App.css';
import Divider from '@mui/material/Divider';
import { Footer } from './Footer';
import { Nav } from './nav';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function ContactUs() {
  return (
    <div className="App">
      <div className='InnerApp'>
        <Nav />
        <Grid container spacing={2} sx={{pt: '200px'}}>
          <Grid item xs={4}>
            <img src='/contactQR.png' width="240px" alt='QRcode'/>
          </Grid>
          <Grid item xs={4} sx={{color: '#FFF'}}>
            <Typography variant='h4' align='left'>
              Executive Producer: 李雨晴
            </Typography>
            <Typography variant='h4' align='left'>
              Tel/Wechat: 13070125001
            </Typography>
            <Typography variant='h4' align='left'>
              Email: yuqing@daftnreal.com
            </Typography>
          </Grid>

        </Grid>

        <div style={{padding: "48px 0"}}>
          <Divider sx={{borderColor: "#FFF"}}/>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ContactUs;
