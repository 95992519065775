import React from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export const VideoPlayer = ({open, handleClose, videoUrl, poster}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      className="CM-Dialog"
      sx={{padding: '48px', backgroundColor: 'rgb(68 60 60 / 50%)'}}
    >
      <IconButton
        sx={{color: '#FFF', position:'absolute', top: 0, right: 0, zIndex: 99}}
        onClick={handleClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <video src={videoUrl} poster={poster} controls height="100%"/>
    </Dialog>
  )
}