import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OurWorks from './OurWorks';
import OurTeam from './OurTeam';
import ContactUs from './ContactUs';

const theme = createTheme({
  typography: {
    fontFamily: 'MyFont, Arial',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/our-works",
    element: <OurWorks />,
  },
  {
    path: "/our-team",
    element: <OurTeam />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
]);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
