import './App.css';
import Divider from '@mui/material/Divider';
import { Footer } from './Footer';
import { Nav } from './nav';

function OurTeam() {
  return (
    <div className="App">
      <div className='InnerApp'>
        <Nav />

        <img src='/director.png' alt="introduction" width="100%" style={{marginTop: '200px'}}/>
        <img src='/producer.png' alt="introduction" width="100%" style={{marginTop: '96px'}}/>

        <div style={{padding: "48px 0"}}>
          <Divider sx={{borderColor: "#FFF"}}/>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default OurTeam;
