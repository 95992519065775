import Grid from '@mui/material/Grid';

export const Partner = () => (
  <Grid container spacing={3} sx={{paddingX: '15%', justifyContent: 'center', margin: '48px 0'}}>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/mi.png' width="100%" height="100%" alt="MI"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/ticktok.svg' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/dell.png' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/huawei.png' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/intel.png' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/vivo.png' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/wangyiyun.svg' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/kuai-shou.svg' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item" sx={{overflow: 'hidden'}}>
      <img src='/logos/tmall.png' width="100%" height="100%" alt="Ticktok" style={{transform: "translateY(-100px)", filter: "drop-shadow(#FFF 0 100px)"}}/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/taobao.png' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/mcdonalds.svg' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/nongfushanquan.png' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/yili.jpg' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item" sx={{overflow: 'hidden'}}>
      <img src='/logos/nestle.png' width="100%" height="100%" alt="Ticktok" style={{transform: "translateY(-140px)", filter: "drop-shadow(#FFF 0 140px)"}}/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/redbull.svg' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/libai.png' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/lancome.png' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/shell.svg' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/motorola.svg' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/hp.svg' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/qualcomm.svg' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/samsung.png' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/chevron.png' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/infiniti.svg' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/dunlop.svg' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
    <Grid item lg={1.5} className="Partner-item">
      <img src='/logos/subaru.png' width="100%" height="100%" alt="Ticktok"/>
    </Grid>
  </Grid >
)