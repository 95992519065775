import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from "react-router-dom";

export const Nav = () => {
  const {pathname} = useLocation();
  const navigate = useNavigate();

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      width: '100%',
      backgroundColor: '#FFF',
    }
  });
  
  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 20,
      marginRight: theme.spacing(1),
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover': {
        color: '#fff',
        opacity: 1,
      },
      '&.Mui-selected': {
        color: '#fff',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
    }),
  );

  const RouteTabMap = {
    '/': undefined,
    '/our-works': 0,
    '/our-team': 1,
    '/contact-us': 2
  }

  const TabRouteIndex = ['/our-works', '/our-team', '/contact-us']

  const handleChange = (event, newValue) => {
    navigate(TabRouteIndex[newValue])
  };

  return (
    <header className="App-header">
      <div className="nav-logo" onClick={()=>{navigate('/')}}/>
      <StyledTabs value={RouteTabMap[pathname]} onChange={handleChange} aria-label="basic tabs example" sx={{ml: '48px'}}>
        <StyledTab label="我们的作品" />
        <StyledTab label="我们的成员" />
        <StyledTab label="联系我们" />
      </StyledTabs>
    </header>
  )
}