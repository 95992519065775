import './App.css';
import {useEffect, useState} from 'react'
import Divider from '@mui/material/Divider';
import { Footer } from './Footer';
import { Nav } from './nav';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { VideoPlayer } from './videoPage';

function OurWorks() {
  const [open, setOpen] = useState(false)
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setVideo] = useState({
    videoUrl: null,
    poster: null,
  })

  useEffect(()=>{
    fetch('/resource.json')
    .then(res=> res.json())
    .then(data=>{
      setVideos(data);
    })
    .catch(e=>console.log(e))
  }, [])

  const onClickImg = (img, video) => () => {
    setVideo({
      videoUrl: video,
      poster: img,
    })
    setOpen(true)
  }

  return (
    <div className="App">
      <div className='InnerApp'>
        <Nav />

        <Box sx={{ width: "90%", margin: '0 auto', pt: '128px' }}>
          <ImageList variant="masonry" cols={4} gap={36}>
            {videos.map((item) => (
              <ImageListItem className='VideoListItem' key={item.name} onClick={onClickImg(item.thumbnail, item.link)}>
                <div style={{display: 'inline-flex', flexDirection: 'column', width: '100%'}}>
                  <img
                    src={item.thumbnail}
                    alt={item.name}
                    width={"100%"}
                    loading="lazy"
                    />
                  <ImageListItemBar
                    title={item.name}
                    position="below"
                    sx={{color: 'white', textAlign: 'left', '.MuiImageListItemBar-title': {whiteSpace: 'normal'}}}
                    />
                </div>
              </ImageListItem>
            ))}
          </ImageList>
        </Box>

        <div style={{padding: "48px 0"}}>
          <Divider sx={{borderColor: "#FFF"}}/>
        </div>
        <Footer />
      </div>
      <VideoPlayer open={open} handleClose={() => setOpen(false)} videoUrl={selectedVideo.videoUrl} poster={selectedVideo.poster}/>
    </div>
  );
}

export default OurWorks;
