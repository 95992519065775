import Typography from '@mui/material/Typography';

export const Footer = () => (
  <div style={{paddingBottom: '48px'}}>
    <Typography variant="body2" component="p" align='left' sx={{color:'white'}}>
      © 2023 - 德融 DAFT & REAL PRODUCTION Pty Ltd
    </Typography>
    <Typography variant="body2" component="p" align='left' sx={{color:'white'}}>
      Contact: Tel/Wechat: 13070125001 李雨晴
    </Typography>
  </div>
)