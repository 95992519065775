import Typography from '@mui/material/Typography';

export const MainLogo = () => (
  <div className='App-Font-1'>
    <Typography variant="h5" component="h2" align='left' sx={{color:'white'}}>
    “德融。行直心也，君子修德，
    </Typography>
    <Typography variant="h5" component="h2" align='left' sx={{color:'white'}}>
      當知直心為道场，乃践行之。
    </Typography>
    <Typography variant="h5" component="h2" align='left' sx={{color:'white'}}>
      聚和之，其乐也融融。”
    </Typography>
  </div>
)